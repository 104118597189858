import { OnInit, ViewChildren, QueryList } from "@angular/core";
import { IUpdateComponent, ICargandoComponent } from "@app/core/interfaces";
import { Observable, zip, Subscription } from "rxjs";

export class BaseComponent implements OnInit, ICargandoComponent {
  @ViewChildren("onUpdateComponent")
  public onUpdateComponents: QueryList<IUpdateComponent>;

  public cargando: boolean = true;
  public error: boolean = false;
  public mensaje: string = "";

  public subscriptions: Subscription[] = [];
  public permisos = [];

  constructor() { }

  ngOnInit() {
    this.onInit().subscribe(_ => {
      this.cargando = false;
     });
  }

  ngOnDestroy() {
    this.onDestroy().subscribe(_ => {
      this.subscriptions.forEach(subscription => {
        subscription.unsubscribe();
      });
    });
  }

  ngAfterViewInit() {
    this.afterViewInit().subscribe(_ => {
      this.cargarDatos();
    });
  }

  public tienePermiso(permiso: string): boolean {
    return this.permisos.find(item => item === permiso);
  }

  public cargarDatos() {
    this.cargando = true;
    this.updateDatos().subscribe(_ => {
      this.cargando = false;
    });
  }

  public updateDatos(): Observable<any> {
    let updates = this.onUpdateComponents.map(component => {
      return component.onUpdate();
    });
    return zip(...updates, this.onUpdate());
  }

  /* IMPLEMENTAR */
  public onInit(): Observable<any> {
    return new Observable(observer => {
      observer.next();
      observer.complete();
    });
  }

  public onUpdate(): Observable<any> {
    return new Observable(observer => {
      observer.next();
      observer.complete();
    });
  }

  public afterViewInit(): Observable<any> {
    return new Observable(observer => {
      observer.next();
      observer.complete();
    });
  }

  public onDestroy(): Observable<any> {
    return new Observable(observer => {
      observer.next();
      observer.complete();
    });
  }
}
